import React from "react"
import LayoutContent from "../../components/Layout/LayoutContent"
import {graphql} from "gatsby"
import MdxProvider from "parsley-core/src/components/MdxProvider/MdxProvider"
import ContentDetail from "parsley-core/src/components/ContentDetail/ContentDetail";
import Grid from "parsley-core/src/components/Grid/Grid";
import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";

const Services = ({ data }, props) => {
	const servicesBreast = data.servicesBreast
	const servicesCancer = data.servicesCancer
	const servicesGeneral = data.servicesGeneral
	const servicesProcedure = data.servicesProcedure

	return (
		<LayoutContent title="Services" className={props.className}>
			<PStyle className="h4">Dr Matthew Beck offers a full range of general surgical services. His broad experience allows him to care for patients requiring complex and minor surgical procedures in the following areas:</PStyle>
			<GridStyle gridGap="var(--s5)" className="grid-2">
				<ContentDetail>
					<H2Style>{servicesBreast.frontmatter.title}</H2Style>
					<MdxProvider>{servicesBreast.body}</MdxProvider>
				</ContentDetail>
				<GatsbyImageStyle alt="" image={servicesBreast.childrenFile[0].childImageSharp.gatsbyImageData} />
			</GridStyle>
			<GridStyle gridGap="var(--s5)" className="grid-2">
				<ContentDetail>
					<H2Style>{servicesCancer.frontmatter.title}</H2Style>
					<MdxProvider>{servicesCancer.body}</MdxProvider>
				</ContentDetail>
				<GatsbyImageStyle className="feature-image" alt="" image={servicesCancer.childrenFile[0].childImageSharp.gatsbyImageData} />
			</GridStyle>
			<GridStyle gridGap="var(--s5)" className="grid-2">
				<ContentDetail>
					<H2Style>{servicesGeneral.frontmatter.title}</H2Style>
					<MdxProvider>{servicesGeneral.body}</MdxProvider>
				</ContentDetail>
				<GatsbyImageStyle alt="" image={servicesGeneral.childrenFile[0].childImageSharp.gatsbyImageData} />
			</GridStyle>
			<GridStyle gridGap="var(--s5)" className="grid-2">
				<ContentDetail>
					<H2Style>{servicesProcedure.frontmatter.title}</H2Style>
					<MdxProvider>{servicesProcedure.body}</MdxProvider>
				</ContentDetail>
				<GatsbyImageStyle className="feature-image" alt="" image={servicesProcedure.childrenFile[0].childImageSharp.gatsbyImageData} />
			</GridStyle>
		</LayoutContent>
	)
}

const GridStyle = styled(Grid)`
	align-items:center;
  	margin-bottom:var(--s6);
  
  @media (min-width: 48em) {
    .feature-image {
      order: -1;
    }
  }
`

const GatsbyImageStyle = styled(GatsbyImage)`
	border:var(--layout-space-support) solid var(--color-tertiary);
	@supports (border:var(--layout-space) solid var(--color-tertiary)) {
		border:var(--layout-space) solid var(--color-tertiary);
	}
	background:var(--color-tertiary);
  
  
    max-width: calc(2 * var(--layout-space-support) + 32.5em);
	@supports (max-width: calc(2 * var(--layout-space) + 32.5em)) {
		max-width: calc(2 * var(--layout-space) + 32.5em);
	}
`

const H2Style = styled.h2`
	padding-bottom:var(--s3);
	margin-bottom:var(--s0);
  	position:relative;
  
	&:after {
	  position:absolute;
	  bottom:0;
	  left:0;
	  width:10ch;
	  height:var(--s-5);
	  background:var(--color-primary);
	  content: ' '
	}
`

const PStyle = styled.p`
	margin-bottom:var(--s5);
  line-height:var(--line-height);
`

export default Services

export const pageQuery = graphql`  
  query {
  servicesBreast: mdx(fileAbsolutePath: {regex: "/service-breast.mdx/"}) {
    body
    frontmatter {
      title
    }
    childrenFile {
      childImageSharp {
        gatsbyImageData(
        	breakpoints: [400, 520]
			layout: CONSTRAINED
			quality: 50
			width: 520
		)
      }
    }
  }
  servicesCancer: mdx(fileAbsolutePath: {regex: "/service-cancer.mdx/"}) {
    body
    frontmatter {
      title
    }
    childrenFile {
      childImageSharp {
        gatsbyImageData(
        	breakpoints: [400, 520]
			layout: CONSTRAINED
			quality: 50
			width: 520
		)
      }
    }
  }
  servicesGeneral: mdx(fileAbsolutePath: {regex: "/service-general.mdx/"}) {
    body
    frontmatter {
      title
    }
    childrenFile {
      childImageSharp {
        gatsbyImageData(
        	breakpoints: [400, 520]
			layout: CONSTRAINED
			quality: 50
			width: 520
		)
      }
    }
  }
  servicesProcedure: mdx(fileAbsolutePath: {regex: "/service-procedure.mdx/"}) {
    body
    frontmatter {
      title
    }
    childrenFile {
      childImageSharp {
        gatsbyImageData(
        	breakpoints: [400, 520]
			layout: CONSTRAINED
			quality: 50
			width: 520
		)
      }
    }
  }
}
`